<template>
  <q-page class="q-my-md">
    <div class="container">
      <div class="row items-center justify-center" style="height:80vh">
        <div class="col-xs-12 col-md-12 text-center text-h5 text-grey-6">
            Aguarde, esta página está em construção
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {}
</script>

<style lang="scss">
</style>
